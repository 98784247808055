<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Update Customer</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="id_perusahaan_customer"
                    >Perusahaan
                  </label>
                  <select
                    v-model="form.id_perusahaan_customer"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_perusahaan_customer = ''"
                    :class="{
                      'is-valid': form.id_perusahaan_customer != '',
                      'is-invalid':
                        formValidate.id_perusahaan_customer ||
                        form.id_perusahaan_customer == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="perusahaan in listPerusahaan"
                      :key="perusahaan.id_perusahaan_customer"
                      :value="perusahaan.id_perusahaan_customer"
                    >
                      {{ perusahaan.nama_perusahaan_customer }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="nama_customer"
                    >Nama Customer
                  </label>
                  <input
                    v-model="form.nama_customer"
                    type="email"
                    class="form-control"
                    placeholder="Input Nama Customer"
                    required
                    @input="formValidate.nama_customer = ''"
                    :class="{
                      'is-valid': form.nama_customer != '',
                      'is-invalid':
                        formValidate.nama_customer ||
                        form.nama_customer == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="isChecked"
                    >Hp Customer
                  </label>
                  <input
                    v-model="form.no_hp_customer"
                    type="tel"
                    class="form-control"
                    placeholder="Input Hp Customer"
                    required
                    @input="formValidate.no_hp_customer = ''"
                    :class="{
                      'is-valid': form.no_hp_customer != '',
                      'is-invalid':
                        formValidate.no_hp_customer ||
                        form.no_hp_customer == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="alamat_customer"
                    >Alamat Customer
                  </label>
                  <input
                    v-model="form.alamat_customer"
                    type="tel"
                    class="form-control"
                    placeholder="Input Alamat Customer"
                    required
                    @input="formValidate.alamat_customer = ''"
                    :class="{
                      'is-valid': form.alamat_customer != '',
                      'is-invalid':
                        formValidate.alamat_customer ||
                        form.alamat_customer == '',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-md-6 mb-2">
                <a
                  href="javascript:window.history.go(-1);"
                  class="btn btn-default"
                  >Kembali</a
                >
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-success float-md-right"
                  @click="postData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../../plugins/userData";

export default {
  mixins: [userData],
  data() {
    return {
      form: {
        id_customer: "",
        id_perusahaan_customer: "",
        nama_customer: "",
        alamat_customer: "",
        no_hp_customer: "",
      },
      listPerusahaan: [],
      formValidate: [],
      loading: false,
    };
  },
  mounted() {
    this.getCatergoryCustomer();
    const id = this.$route.params.id;
    if (id) {
      this.fetchData(id);
    }
  },
  methods: {
    getCatergoryCustomer() {
      axios
        .get(this.$url+"/v2/perusahaan_customer/")
        .then((response) => {
          this.listPerusahaan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData(id) {
      axios
        .get(this.$url+"/v2/customer/detail/" + id)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.$url+"/v2/customer/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>